import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to) => {
    const nomeEmpresa = String(to.query.c).toLowerCase()
    const uuid = String(to.query.u);

    let url = nomeEmpresa === 'exata' ? 'http://classificacao.ddns.net/#/' : `http://alfameta.ddns.net/${nomeEmpresa}/#/`;
    url += `impressodinamico/${uuid}`;

    window.location.replace(url);
});

export default router
